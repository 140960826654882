export class Vacation {
  IdVacation: string;
  IdEmployee: string;
  StartDate: string;
  EndDate: string;
  CurrentYear: string;
  Days: number;
  Observations: string;

  constructor(data: any) {
    this.IdVacation = data.IdVacation;
    this.IdEmployee = data.IdEmployee;
    this.StartDate = data.StartDate;
    this.EndDate = data.EndDate;
    this.CurrentYear = data.CurrentYear;
    this.Days = data.Days;
    this.Observations = data.Observations;
  }

}
