import {User} from './user.model';

export class UserPagination {
  data: User[];
  total: number;

  constructor(data: any) {
    this.data = data.data.map(u => new User(u));
    this.total = data.count;
  }


}
