import { compare } from 'fast-json-patch';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { empty, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SpecialTask } from '../../models/specialTask/specialTask.model';

@Injectable({
  providedIn: 'root'
})
export class SpecialTaskService {
  private url = `${environment.api}/api/services/`;

  constructor(private http: HttpClient) {
  }


  /**
   * Returns default service for debugging purposes
   */
  public defaultSpecialTask() {
    return {
      specialTasks:
      {
        IdMobileTeamTask: 0,
        IdService: 0,
        TaskType: null,
        Billing: false,
        EstimatedAmount: 0,
        EstimatedCost: 0,
        EffectiveCost: 0,
        Windowpanes: false,
        PaperPickup: false,
        Polished: false,
        Supplies: false,
        Other: null,
        Observations: null,
      },
    };
  }

  /**
   * Returns observable that calls to API when the pagination or the filters changes
   *
   * @param id
   */
  public getSpecialTask(id: string): Observable<SpecialTask> {
    return this.http.get(`${this.url}${id}/MobileTeamTasks`).pipe(
      catchError(err => of(this.defaultSpecialTask().specialTasks)),
      map((d: any) => new SpecialTask(d.data))
    );
  }

  public createSpecialTask(task,id) {
    delete task.IdService;
    delete task.IdMobileTeamTask;
    return this.http.post(`${this.url}${id}/MobileTeamTasks`, task);
  }

  public updateSpecialTask(oldTask, newTask) {
    const id = oldTask.IdService;
    delete oldTask.IdService;
    delete oldTask.IdMobileTeamTask;
    delete newTask.IdService;
    delete newTask.IdMobileTeamTask;
    const patch = compare(oldTask, newTask);
    return this.http.patch(`${this.url}${id}/MobileTeamTasks`, patch);
  }

}
