export class Supply {
  Amount: number;
  Date: string;
  Employee: string;
  IdEmployee: string;
  IdMaterial: string;
  IdService: string;
  IdSupply: string;
  Material: string;

  constructor(data: any) {
    this.Amount = data.Amount;
    this.Date = data.Date;
    this.Employee = data.Employee;
    this.IdEmployee = data.IdEmployee;
    this.IdMaterial = data.IdMaterial;
    this.IdService = data.IdService;
    this.IdSupply = data.IdSupply;
    this.Material = data.Material;
  }

}
