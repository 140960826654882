export class User {
  id: string;
  name: string;
  email: string;
  role: string;
  password: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.role = data.role;
    this.password = data.password;
  }

  static tableColumns(): string[] {
    return ['name', 'email', 'role', 'actions'];
  }

  public is(role: string): boolean {
    return role === this.role;
  }

}
