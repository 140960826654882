export class SpecialTask {
  IdMobileTeamTask: number;
  IdService: number;
  TaskType?: string;
  Billing?: boolean;
  EstimatedAmount?: number;
  EstimatedCost?: number;
  EffectiveCost?: number;
  Windowpanes?: boolean;
  PaperPickup?: boolean;
  Polished?: boolean;
  Supplies?: boolean;
  Other?: string;
  Observations?: string;

  constructor(data: any) {
    this.IdMobileTeamTask = data.IdMobileTeamTask;
    this.IdService = data.IdService;
    this.TaskType = data.TaskType;
    this.Billing = data.Billing;
    this.EstimatedAmount = data.EstimatedAmount;
    this.EstimatedCost = data.EstimatedCost;
    this.EffectiveCost = data.EffectiveCost;
    this.Windowpanes = data.Windowpanes;
    this.PaperPickup = data.PaperPickup;
    this.Polished = data.Polished;
    this.Supplies = data.Supplies;
    this.Other = data.Other;
    this.Observations = data.Observations;
  }

}
