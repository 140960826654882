import { compare } from 'fast-json-patch';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { empty, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Supply } from '../../models/supply/supply.model';

@Injectable({
  providedIn: 'root'
})
export class SupplyService {
  private url = `${environment.api}/api/services/`;

  constructor(private http: HttpClient) {
  }


  /**
   * Returns observable that calls to API when the pagination or the filters changes
   *
   * @param id
   */
  public getSupply(id: string): Observable<Supply[]> {
    return this.http.get(`${this.url}${id}/supplies`).pipe(
      map((data: Supply[]) => data.map(d => new Supply(d)))
    );
  }

  public createSupply(task, id) {
    return this.http.post(`${this.url}${id}/supplies`, task);
  }

  public updateSupply(oldTask, newTask) {
    const id = oldTask.IdService;
    delete oldTask.IdService;
    delete oldTask.IdMobileTeamTask;
    delete newTask.IdService;
    delete newTask.IdMobileTeamTask;
    const patch = compare(oldTask, newTask);
    return this.http.patch(`${this.url}${id}/supplies`, patch);
  }

}
