import { Material } from './../../models/material/material.model';
import { compare } from 'fast-json-patch';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { empty, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  private url = `${environment.api}/api/materials`;

  constructor(private http: HttpClient) {
  }


  /**
   * Returns observable that calls to API when the pagination or the filters changes
   *
   * @param id
   */
  public getMaterials(): Observable<Material[]> {
    return this.http.get(`${this.url}`).pipe(
      map((data: Material[]) => data.map(d => new Material(d)))
    );
  }

  deleteMaterials(id) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteSupply(idService,idSupply){
    return this.http.delete(`${environment.api}/api/services/${idService}/supplies/${idSupply}`);
  }

  addMaterial(name) {
    return this.http.post(`${this.url}`, { Name: name });
  }

}
