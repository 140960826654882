export class Work {
  IdWork: string;
  IdService: string;
  IdEmployee: string;
  StartHour: string;
  EndHour: string;
  WeekDay: number;
  Paused: boolean;
  Employee: string;

  constructor(data: any) {
    this.IdWork = data.IdWork;
    this.IdService = data.IdService;
    this.IdEmployee = data.IdEmployee;
    this.StartHour = data.StartHour;
    this.EndHour = data.EndHour;
    this.WeekDay = data.WeekDay;
    this.Paused = data.Paused;
    this.Employee = data.Employee;
  }

}
