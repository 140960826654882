export class Material {
  IdMaterial: string;
  Name: string;

  constructor(data: any) {
    this.IdMaterial = data.IdMaterial;
    this.Name = data.Name;
  }

}
