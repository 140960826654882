import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { empty, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { compare } from 'fast-json-patch';
import { UtilsService } from '../utils.service';
import { User } from '../../models/user/user.model';
import { UserPagination } from '../../models/user/userPagination.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public pagination$ = new Subject<any>();
  private url = `${environment.api}/api/authManagement/`;

  constructor(private http: HttpClient, private utils: UtilsService) {
  }

  /**
   * Static method returns form to filter users
   */
  public static userFilters(): FormGroup {
    return new FormGroup({
      name: new FormControl(null),
      email: new FormControl(null),
      role: new FormControl(null),
    });
  }

  /**
   * Static method returns user form
   *
   * @param user
   */
  public static userForm(user: User): FormGroup {
    return new FormGroup({});
  }

  /**
   * Returns observable that calls to API when the pagination or the filters changes
   */
  public getUsers(): Observable<UserPagination> {
    return this.pagination$.pipe(
      switchMap(d => this.http.get(`${this.url}users/`,
      ).pipe(
        catchError(err => of({ data: [], count: 0 })),
      )),
      map(d => new UserPagination(d))
    );
  }

  /**
   * Returns specific user
   *
   * @param id of the user
   */
  public getUserById(id): Observable<User> {
    return this.http.get(`${this.url}${id}`).pipe(
      catchError(err => of()),
      map((data) => new User(data))
    );
  }

  /**
   * Endpoint for the master to update the users
   *
   * @param e user
   */
  public updateUser(user, oldUser, id): Observable<any> {
    const newUser = {};
    Object.keys(user).map(d => {
      if (user[d] !== oldUser[d] && user[d] !== null) {
        newUser[d] = user[d];
      }
    });
    return this.http.put(`${this.url}${id}`, newUser);
  }

  /**
   * Endpoint for the admin to make a request to change an user
   *
   * @param e user
   */
  public requestUser(e: User, original: User): Observable<any> {
    const patch = compare(original, e);
    return this.http.patch(`${this.url}${e.id}`, patch);
  }

  public addUser(e: User): Observable<any> {
    return this.http.post(`${environment.api}/api/authManagement/register`, e);
  }


}
