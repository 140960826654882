import { compare } from 'fast-json-patch';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Log } from '../../models/log/log.model';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Work } from '../../models/work/work.model';

@Injectable({
  providedIn: 'root'
})
export class WorkService {
  private url = `${environment.api}/api/services/`;


  constructor(private http: HttpClient) {
  }


  getDefaultWorks(): Array<any> {
    return [
      // {
      //   id: 'id',
      //   service: 'IDS1',
      //   employee: 'IDE1',
      //   startHour: '11:30',
      //   endHour: '12:00',
      //   weekDay: 0,
      //   paused: false,
      // },
      // {
      //   id: 'id',
      //   service: 'IDS1',
      //   employee: 'IDE1',
      //   startHour: '11:00',
      //   endHour: '12:00',
      //   weekDay: 1,
      //   paused: false,
      // },
      // {
      //   id: 'id',
      //   service: 'IDS1',
      //   employee: 'IDE2',
      //   startHour: '13:00',
      //   endHour: '14:00',
      //   weekDay: 2,
      //   paused: false,
      // },
    ];
  }

  getWorksById(id: string): Observable<Work[]> {
    return this.http.get(`${this.url}${id}/works`).pipe(
      catchError(err => of(this.getDefaultWorks())),
      map((data: []) => data.map(d => new Work(d)))
    );
  }

  addWork(work, id) {
    return this.http.post(`${this.url}${id}/works`, work);
  }
  deleteWork(work, id) {
    return this.http.delete(`${this.url}${id}/works/${work}`, work);
  }
  editWork(oldWork, newWork, id) {
    const patch = compare(oldWork, newWork);
    return this.http.patch(`${this.url}${id}/works/${oldWork.IdWork}`, patch);
  }
}
