import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {Vacation} from '../../models/vacation/vacation.model';
import {environment} from '../../../environments/environment';
import {catchError, map, switchMap} from 'rxjs/operators';
import {compare} from 'fast-json-patch';

@Injectable({
  providedIn: 'root'
})
export class VacationService {
  public year$ = new Subject<number>();
  private url = `${environment.api}/api/employees/`;


  constructor(private http: HttpClient) {
  }

  /**
   * Returns observable that calls api for a certain user when the year subject chanegs
   *
   * @param id
   */
  public getVacations(id): Observable<Vacation[]> {
    return this.year$.pipe(
      switchMap(y => this.http.get(`${this.url}${id}/vacations/${y}`).pipe(
        catchError(err => of([])),
      )),
      map((v: any) => v.map(d => new Vacation(d)))
    );
  }

  public getVacationYears(id) {
    return this.http.get(`${this.url}${id}/vacations`).pipe(
      catchError(err => of([new Date().getFullYear()]))
    );
  }

  public addVacation(vacation: Vacation, id: string): Observable<any> {
    delete vacation.IdEmployee;
    return this.http.post(`${this.url}${id}/vacations`, vacation);
  }

  public removeVacation(vacation: number, id: string): Observable<any> {
    return this.http.delete(`${this.url}${id}/vacations/${vacation}`);
  }

  public removeVacationByYear(year: number): Observable<any> {
    return this.http.delete(`${this.url}0/vacations/year/${year}`);
  }

  /**
   * Update the user-vacation
   *
   * @param old Old object
   * @param vacation Changed object
   */
  public updateVacation(old, vacation): Observable<any> {
    const patch = compare(old, vacation);
    return this.http.patch(`${this.url}${old.IdEmployee}/vacations/${old.IdVacation}`, patch);
  }


}
